import React from "react";
import { Route, BrowserRouter } from "react-router-dom";

import App from "./App";
import Home from "./Home";
import Clients from "./Clients";

const RoutesContainer = () => {
    return (
        <BrowserRouter>
            <Route component={Home} path="/" exact />
            <Route component={App} path="/recover/users/reset/password/:token" />
            <Route component={Clients} path="/recover/clients/reset/password/:token" />
        </BrowserRouter>
    )
}

export default RoutesContainer;