import React from 'react';

import "../App.css";

function Loader() {
    return (
      <div className="overlay">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
    )
}
export default Loader;