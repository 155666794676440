import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useParams
} from "react-router-dom";

import Loader from './Components/loader'

import "./App.css";
import { ReactComponent as Logo } from "./Assets/gurumed-color.svg";
import { ReactComponent as Eye } from "./Assets/eye.svg";

function Clients() {
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [userFeedback, setUserFeedback] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(false);
  const [confirmedPasswordMessage, setConfirmedPasswordMessage] =
    useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);

  function handleNewPassword({ target }) {
    setNewPassword(target.value);
    if (target.value.length >= 4) setPasswordMessage(false);
  }

  function handleConfirmedNewPassword({ target }) {
    setConfirmedNewPassword(target.value);
    if (newPassword === target.value) setConfirmedPasswordMessage(false);
  }

  function handleNewPasswordBlur() {
    if (newPassword.length < 4) setPasswordMessage(true);
    else setPasswordMessage(false);
  }

  function handleConfirmedPasswordBlur() {
    if (newPassword !== confirmedNewPassword) setConfirmedPasswordMessage(true);
    else setPasswordMessage(false);
  }



  function handlePasswordVisible(verification) {
    if (verification === 1) setPasswordVisible((oldState) => !oldState);
    if (verification === 2)
      setPasswordConfirmationVisible((oldState) => !oldState);
  }

  useEffect(() => {
    if (
      confirmedNewPassword.length >= 4 &&
      confirmedNewPassword === newPassword
    )
      setUserFeedback(true);
    else setUserFeedback(false);
  }, [confirmedNewPassword, newPassword]);


  const token = useParams();

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      setLoading(false);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/clients/reset/password/${token.token}`, { password: newPassword });
      setIsLoading(false)
      setNewPassword("");
      setConfirmedNewPassword("");
      toast("Senha redefinida com sucesso");
    } catch (error) {
      toast.error('Erro ao redefinir senha', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setIsLoading(false)
      setLoading(false);
    }
  }

  return isLoading ? <Loader /> : (
    <div className="App">
       <ToastContainer />
      <section className="card">
        <Logo className="logo" alt="Logo Gurumed" />
        <form onSubmit={handleSubmit}>
          <label for="newpassword">Nova senha:</label>
          <span
            className="input"
            style={{
              borderColor: userFeedback && "#00c5ae",
            }}
          >
            <input
              type={passwordVisible ? "text" : "password"}
              id="newpassword"
              name="newpassword"
              placeholder="senha nova"
              onChange={handleNewPassword}
              onBlur={handleNewPasswordBlur}
              value={newPassword}
            />
            <Eye onClick={() => handlePasswordVisible(1)} />
          </span>
          {passwordMessage && (
            <span className="userMessage">
              A senha deve ter no mínimo 4 caracteres
            </span>
          )}
          <label for="confirmpassword">Confirme a senha:</label>
          <span
            className="input"
            style={{
              borderColor: userFeedback && "#00c5ae",
            }}
          >
            <input
              type={passwordConfirmationVisible ? "text" : "password"}
              id="confirmpassword"
              name="confirmpassword"
              placeholder="confirme a senha"
              value={confirmedNewPassword}
              onChange={handleConfirmedNewPassword}
              onBlur={handleConfirmedPasswordBlur}
            />
            <Eye onClick={() => handlePasswordVisible(2)} />
          </span>

          {confirmedPasswordMessage && (
            <p className="userMessage">Senhas não conferem</p>
          )}

          <button
            disabled={!userFeedback}
            style={{ opacity: loading && 0.5, cursor: loading && "progress" }}
          >
            {loading ? "Alterando..." : "Alterar senha"}
          </button>
        </form>
      </section>
    </div>
  )
}

export default Clients;
