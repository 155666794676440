import React from 'react';


function Home() {
    return (
        <h1>NOTHING HERE</h1>
    )
}


export default Home;